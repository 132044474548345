<template>
    <div class="menu-body" v-if="$oidc.userProfile.roles.includes(roles.PLANTSUBPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)
        || $oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER)">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committed_year"
                            v-model="committedYr" @change="filterHandler()" disabled>
                            <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select disabled class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            :value="plantSelectedInAsmtHome" @change="plantChanged($event); filterHandler();">
                            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                {{ plant.plantName }}, {{ plant.regionCode }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="workcenter" class="form-label select-lable lable-text"> Work Center </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                            v-model="workcenterId"
                            @change="showLoader = true; getTargetSetMachines(); getTargetNotSetMachines()">
                            <option v-for="wc in workCenterList" v-bind:key="wc.wcId" :value="wc.wcId">
                                {{ wc.workcenterName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap" v-if="machineDetail.length || clearMachines.length">
                <div class="machine-action-tracker">
                    <b-table-simple responsive outlined id="machine-action-tracker" sticky-header
                        class="machine-action-table scroll gn-table-b">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th v-for="header in machineDetailHeader" :key="header.key">{{ header.label }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if="machineDetail && machineDetail.length !== 0">
                            <b-tr v-for="(rowData, index) in machineDetail" :key="index"
                                :class="{ 'add-top-border': rowData.addBorder }">

                                <b-td class="machine-action-tracker-label"
                                    v-if="rowData.hasOwnProperty('noOfPillar') && rowData.noOfPillar > 1"
                                    :rowspan="rowData.noOfPillar">
                                    {{ rowData.machineName }}
                                </b-td>

                                <b-td class="machine-action-tracker-label">
                                    {{ rowData.pillarCode }}
                                </b-td>

                                <b-td>
                                    <div>{{ rowData.jan }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.feb }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.mar }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.apr }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.may }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.jun }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.jul }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.aug }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.sep }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.oct }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.nov }}</div>
                                </b-td>
                                <b-td>

                                    <div>{{ rowData.dec }}</div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tbody v-if="machineDetail && machineDetail.length === 0">
                            <tr>
                                <td class="text-center" style="height: 380px" rowspan="6"
                                    :colspan="machineDetailHeader.length">
                                    <img alt="no data" src="@/assets/No_Data.svg" />
                                    <div class="ed-date" style="margin-top: 1rem;">
                                        Please set target for a machines and then proceed to Initiate Approvals.
                                    </div>
                                </td>
                            </tr>
                        </b-tbody>
                        <b-tfoot> </b-tfoot>
                    </b-table-simple>
                </div>
            </div>

            <div v-if="machineDetail.length || clearMachines.length">
                <label class="approval-heading">
                    Please note that if you initiate approval, target will be set to 0 for all the following machines:
                </label>
            </div>

            <div class="full-length-width display-flex" v-if="machineDetail.length || clearMachines.length">
                <div class="col-12  approval-border-right" style="padding:0.5rem 2rem !important">
                    <div id="approval-work-centers-id" class="approval-work-centers scroll" v-if="clearMachines.length">
                        <div v-for="item in clearMachines" :key="item.machineId" class="individual-checkbox">
                            <div class="chk-container">
                                {{ item.machineName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!(machineDetail.length || clearMachines.length)" class="already-initiate-block">
                <div class="text-center">
                    <img alt="no data" src="@/assets/No_Data.svg" />
                    <div class="ed-date" style="margin-top: 1rem;">
                        Targets are locked and sent for approval.
                    </div>
                </div>
            </div>

            <div class="full-length-width display-flex" style="justify-content: end;">
                <b-button class="asmt-bt button-sm" style="padding: 0.5rem 1.2rem; margin-right: 2rem" pill
                    variant="outline-primary" @click="$router.go(-1)">
                    Go back
                </b-button>
                <b-button v-if="machineDetail.length !== 0" class="asmt-bt button-sm"
                    style="padding: 0.5rem 1.2rem; margin-right: 2rem" pill variant="outline-primary"
                    :disabled="!(machineDetail.length || clearMachines.length)" @click="showConfirmBox()">
                    Initiate Approvals

                </b-button>
            </div>
        </div>
        <!-- confirmation popup -->
        <b-modal id="initiateConfirmBox" size="lg" ref="initiateConfirmBox" hide-footer :title="confirmationHeadText"
            :visible="showConfirmation">
            <div class="row col-12 page-content-regular">
                <p style="margin-bottom: 6rem;">
                    <span class="main-tab-act default-font-color ">
                        {{ confirmationMsg }}
                        <br />
                        Are you sure you want to Initiate Approval?
                    </span>
                </p>
            </div>
            <div class="button-div" style="justify-content: right;display: flex;width: 100%;">
                <b-button class="btn ed-st-btn" style="border: transparent; background: transparent; color: #274ba7" pill
                    variant="outline-primary" @click="closeAlertPopup()">
                    Cancel
                </b-button>
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                    class="btn cancel-save-btn button-sm" @click="initiateApprove()"> Proceed </b-button>
            </div>
        </b-modal>
        <!-- confirmation popup -->
        <MessagePopup class="alert" :routToBack="false" :message="errorMsg" :show="showPopup" :headText="headText" />
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import MessagePopup from "@/components/common/MessagePopup.vue";
import roles from "@/common/constants/roles";
export default {
    name: "InitiateApprovalScreen",
    data() {
        return {
            selectedWorkCenter: ["ALL"],
            showLoader: true,
            plantList: [],
            confirmationHeadText: "",
            confirmationMsg: "",
            showConfirmation: false,
            clearMachines: [
                {
                    machineId: 1,
                    machineName: "Machine #1"
                },
                {
                    machineId: 2,
                    machineName: "Machine #2"
                },
                {
                    machineId: 3,
                    machineName: "Machine #3"
                },
                {
                    machineId: 4,
                    machineName: "Machine #4"
                }, {
                    machineId: 5,
                    machineName: "Machine #5"
                }, {
                    machineId: 6,
                    machineName: "Machine #6"
                }, {
                    machineId: 7,
                    machineName: "Machine #7"
                }
            ],
            committedYear: [],
            workCenterList: [],
            workcenterId: '',
            plantId: 1,
            isAllWorkcenterApproved: false,
            exceutiveReview: true,
            exceutiveApprove: false,
            wcSelected: 5,
            committedYr: new Date().getFullYear(),
            machineDetailHeader: [
                {
                    key: "machineName",
                    label: "MACHINES",
                    sortable: false
                },
                {
                    key: "pillarCode",
                    label: "PILLAR",
                    sortable: false
                },
                {
                    key: "jan",
                    label: "JAN",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "feb",
                    label: "FEB",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "mar",
                    label: "MAR",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "apr",
                    label: "APR",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "may",
                    label: "MAY",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jun",
                    label: "JUN",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jul",
                    label: "JUL",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "aug",
                    label: "AUG",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "sep",
                    label: "SEP",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oct",
                    label: "OCT",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "nov",
                    label: "NOV",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "dec",
                    label: "DEC",
                    sortable: false,
                    variant: "success"
                }
            ],
            machineDetail: [],
            roles: roles,
            errorMsg: "",
            headText: "",
            showPopup: false
        };
    },
    components: {
        Breadcrumb,
        Loader,
        MessagePopup
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 3
        });
        this.$store.dispatch("breadcrumb", {
            title: "Plant Optimization Step Audit - Initiate Approval",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Replication Step Audit", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.getCommittedYear();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData", "pillarPrevSelected"])
    },
    methods: {
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.MRCOMMITTEDYEAR, "get").then((data) => {
                this.committedYear = data.data;
                this.committedYr = new Date().getFullYear();
                this.getPlantDetails();
            });
        },
        closeAlertPopup() {
            this.$refs["initiateConfirmBox"].hide();
            this.showConfirmation = false;
        },
        getPlantDetails() {
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getWorkCenterDetails();
            });
        },
        plantChanged(event) {
            console.log("plantChanged");
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
        },
        getWorkCenterDetails() {

            let cYr = this.committedYr;
            let plntId = this.plantId.plantId;

            ApiCalls.httpCallDetails(Api.GETWORKCENTERBYPLANT, "post", {
                cYear: cYr,
                plantId: plntId,
                pillarIds: [4, 5, 6, 7]
            }).then((data) => {
                data.data = data.data.sort((a, b) => { if (a.wcId > b.wcId) return 1; else return a.wcId === b.wcId ? 0 : -1 });

                this.workCenterList = data.data;//isWorkcenterInitiated
                this.workcenterId = data.data[0].wcId
                this.showLoader = true
                this.getTargetSetMachines();
                this.getTargetNotSetMachines();
            });
        },
        getTargetSetMachines() {
            let plantId = this.plantId.plantId;
            let wc = this.workcenterId;
            let cYear = this.committedYr;
            ApiCalls.httpCallDetails(`${Api.GETMACHINESWHOSETARGETSET}${plantId}/${wc}/${cYear}`, "get").then((data) => {
                console.log(data)
                this.showLoader = false;
                this.machineDetail = [];
                data.data.forEach(item => {
                    item.targets.forEach((target, targetPos) => {
                        let obj = {
                            machineId: item.machineId,
                            pillarCode: target.pillarCode,
                            pillarId: target.pillarId,
                            ...target.values,
                            addBorder: targetPos === 0
                        };
                        if (targetPos === 0) {
                            obj['machineName'] = item.machineName;
                            obj['noOfPillar'] = item.targets.length;
                        }
                        this.machineDetail.push(obj)
                    })//clearMachines
                })
            })
        },
        getTargetNotSetMachines() {

            ApiCalls.httpCallDetails(`${Api.GETMACHINESWHOSETARGETNOTSET}`, "post", {
                commitedYear: this.committedYr,
                plantId: this.plantId.plantId,
                wcId: this.workcenterId
            }).then((data) => {
                console.log(data)
                this.showLoader = false;
                this.clearMachines = []
                this.clearMachines = data.data
            })
        },
        showConfirmBox() {
            this.$refs.initiateConfirmBox.show();
            this.confirmationHeadText = ""
            this.confirmationMsg = this.clearMachines.length ? `Target for ${this.clearMachines.length} machines are setting as 0.` : ''
            this.showConfirmation = true
        },
        initiateApprove() {
            this.$refs.initiateConfirmBox.hide();
            this.confirmationHeadText = ""
            this.confirmationMsg = this.clearMachines.length ? `Target for ${this.clearMachines.length} machines are setting as 0.` : ''
            this.showConfirmation = false
            const reqBody = [];

            reqBody.push({
                wcId: this.workcenterId,
                plantId: this.plantId.plantId,
                commitedYear: this.committedYr,
                approverL1: this.$oidc.userProfile.email,
                approverL2: null,
                status: "Initiated",
                pillarId: 0
            });

            let wcIndex = this.workCenterList.findIndex(wc => wc.wcId === this.workcenterId)
            console.log(reqBody);
            this.showPopup = false;
            this.showLoader = true;
            ApiCalls.httpCallDetails(Api.SAVEWORKCENTERBYPLANT, "post", reqBody).then((data) => {
                console.log(data);
                this.showLoader = false;
                this.getTargetSetMachines();
                this.getTargetNotSetMachines()
                if (data.success) {
                    this.errorMsg = `Targets setting has been initiated for Plant ${this.plantId.plantName} and Work center ${this.workCenterList[wcIndex].workcenterName}.`; //data.message.trim
                    this.headText = "";
                    this.showPopup = true;
                } else if (!data.success) {
                    this.errorMsg = "Something went wrong.";
                    this.headText = "";
                    this.showPopup = true;
                }
            });
        }
    }
};
</script>

<style scoped>
.graph-div {
    width: 25%;
    padding: 0 1%;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.already-initiate-block {
    height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plant-dd {
    width: 20.5%;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.mat-needInput-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.add-top-border {
    border-top: 1px solid #eae6e6;
}

.machine-action-tracker {
    margin-top: 2rem;
    width: 100%;
}

.edit-button-div {
    display: flex;
}

.input-div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    /* width: 50px; */
}

.input-f {
    width: 30px;
    text-align: center;
    font-size: 1.33rem;
    border-radius: 4px;
    border: #313131 1px solid;
}

#machine-action-tracker th.table-success {
    background-color: #f4f6fd;
}

#machine-action-tracker th:first-child {
    background-color: white !important;
    font-weight: medium;
}

#machine-target-setting th:first-child,
#machine-target-setting td:first-child {
    text-align: left !important;
    padding-left: 2rem !important;
}

#machine-target-setting tr td {
    border: 0px !important;
    border-bottom: 1px solid #eae6e6 !important;
}

.machine-target-setting-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
}

.machine-action-table-approve {
    max-height: 8rem !important;
}

.machine-action-table {
    max-height: 37rem !important;
}



.custom-control {
    display: flex !important;
}

.machine-action-table thead th {
    background-color: #f7f9ff !important;
}

.machine-action-table thead th,
.machine-action-table td {
    text-align: center !important;
    vertical-align: middle !important;
    min-width: 40px;
}

.save-submit-btn {
    background-color: #274ba7;
    border-color: #274ba7 !important;
    margin-top: 0.4rem;
}

.save-submit-btn:hover {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

.save-submit-btn:active {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

.save-submit-btn:focus {
    box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

#machine-action-tracker>.machine-action-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-action-table th:first-child {
    width: 15%;
}

.machine-action-table td,
.machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.machine-action-table .table-light,
.machine-action-table .table-light>td,
.machine-action-table .table-light>th {
    background-color: #f0f0f0 !important;
}



.machine-action-table th.table-success {
    background-color: #f4f6fd;
}

.machine-action-table th:first-child,
.machine-action-table td:first-child {
    background-color: transparent !important;
}

.machine-action-table td.table-success {
    background-color: transparent;
}

.machine-action-table tr {
    height: 5rem;
}

.machine-action-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.btn {
    margin: 0 0 0 1rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}

.approval-work-centers {
    display: flex;
    min-height: inherit;
    max-height: 15rem;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
}

.approval-heading {
    color: #234372;
    font-size: 1.3333333333333333rem;
    font-family: "BarlowSb", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}


.approval-border-top {
    border-top: 1px solid #f0f0f0;
}

.approval-border-right {
    border-right: 1px solid #f0f0f0;
}

.individual-checkbox {
    /* min-width: 30%;
    max-width: 30%; */
    width: 20rem
}

.review-background-active {
    background-color: #f0f7ff;
    border-radius: 6px;
    padding-top: 2em;
    padding-bottom: 2rem;
    max-width: 45%;
    margin-right: 5%;
}

.persona-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.approvals-save-button {
    padding: 1rem;
    float: right;
    text-align: end;
}

.approval-section-heading-disabled {
    color: #313131;
    opacity: 0.2;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}


.approval-section-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}


.display-flex-align-center {
    margin-left: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.approvals-updated-by {
    color: #5a5a5a;
    font-size: 0.9166666666666666rem;
    font-family: "BarlowR", sans-serif;
    text-align: center;
    padding: 1rem;
}

.table-container {
    height: 500px;
    width: 800px;
    overflow: auto;
}

.table-container table {
    height: 100%;
    width: 100%;
}

.chk-container {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
    padding: 1rem 0;
}


@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
